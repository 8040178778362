import { Controller } from "stimulus"
import Quill from 'src/quill'
import MagicUrl from 'quill-magic-url'
Quill.register('modules/magicUrl', MagicUrl)

export default class extends Controller {
  static targets = [
    'notes', 'editor', 'description', 'descedit', 'client', 'client_option',
    'obj_client_option', 'kanban_select', 'objectives_select'
  ]

  loadKanbanCols() {
    let select = this.kanban_selectTarget
    select.innerHTML = '<option value=""></option>'
    this.availableKbOptions.forEach((el, i) => {
      if(el.dataset.client == event.target.value) {
        select.append(el)
      }
    })
  }

  loadObjectives() {
    let select = this.objectives_selectTarget
    select.innerHTML = '<option value=""></option>'
    this.availableObjOptions.forEach((el, i) => {
      if(el.dataset.client == event.target.value) {
        select.append(el)
      }
    })
  }

  setupOptions() {
    this.availableKbOptions = []
    this.availableObjOptions = []
    let arrayKb = this.availableKbOptions
    let arrayObj = this.availableObjOptions

    let select = this.kanban_selectTarget
    this.client_optionTargets.forEach((el, i) => {
      if(el.dataset.client !== this.clientTarget.value) {
        arrayKb.push(el)
        el.remove()
      }
    })
    let selectobj = this.objectives_selectTarget
    this.obj_client_optionTargets.forEach((el, i) => {
      if(el.dataset.client !== this.clientTarget.value) {
        arrayObj.push(el)
        el.remove()
      }
    })
  }

  prepareParams() {
    if (this.hasNotesTarget) {
      this.notesTarget.value = this.notes_editor.root.innerHTML
      this.descriptionTarget.value = this.description_editor.root.innerHTML
    }
  }

  connect() {
    this.setupOptions()
    if (this.hasNotesTarget) {
      this.setupEditors()
    }
  }

  setupEditors() {
    this.editorTarget.innerHTML = this.notesTarget.value
    this.notes_editor = new Quill(this.editorTarget, {
      theme: 'snow',
      modules: {
        magicUrl: true,
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block'],
          ['link'],
          ['clean']
        ]
      }
    });
    this.notes_editor.clipboard.addMatcher('IMG', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    })
    this.notes_editor.clipboard.addMatcher('PICTURE', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    })
    this.desceditTarget.innerHTML = this.descriptionTarget.value
    this.description_editor = new Quill(this.desceditTarget, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block'],
          ['link'],
          ['clean']
        ]
      }
    });
    this.description_editor.clipboard.addMatcher('IMG', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    })
    this.description_editor.clipboard.addMatcher('PICTURE', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    })
  }
}
