import { Controller } from "stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ['query', 'results']

  connect() {
  }

  query(e) {
    if(e.keyCode == 13) {
      Rails.ajax({
        type: "get",
        url: this.data.get('queryUrl'),
        data: "query=" + e.target.value,
        success: this.handleResults.bind(this)
      })
    }
  }

  handleResults(results) {
    this.resultsTarget.innerHTML = ''
    results.forEach(result => {
      let anchor = document.createElement('a');
      anchor.className = "link w-100 ph3 pv1 bg-white bg-black-20 black-80 flex justify-between mt1";
      anchor.href = result.url;
      anchor.innerHTML = result.match;
      this.resultsTarget.appendChild(anchor);
    })
  }
  // filter() {
  //   let query = this.queryTarget.value.toLowerCase()
  //   this.productTargets.forEach(pc => {
  //     if(pc.dataset.tags.toLowerCase().includes(query)) {
  //       pc.classList.remove('dn')
  //     } else {
  //       pc.classList.add('dn')
  //     }
  //   })
  // }
}
