import consumer from "./consumer"
import { notify } from "src/notifier"
export const NotificationsChannel = () => {

  let args = { channel: "NotificationsChannel" }

  consumer.subscriptions.create(args, {
    received(data) {
      if(data.replaces) {
        this.replaceNotification(data)
      } else {
        this.triggerNotification(data)
      }
    },

    replaceNotification(data) {
      this.triggerNotification(data)
    },

    triggerNotification(data) {
      window.dispatchEvent(new CustomEvent("yt-notification", {
        detail: { notification: data }
      }))
      notify(
        data.title, {
          body: data.body,
          silent: false,
          tag: 'renotify',
          renotify: true,
        }
      )
    }
  })
}
