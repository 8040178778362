import { Controller } from "stimulus"
export default class extends Controller {
  static values = {
    url: { type: String, default: '/to_dos/quick_create_s' }
  }
  static targets = [
    'valid_from', 'to_do_parent', 'title', 'points', 'estimated_hours',
    'assignee_id', 'wrapper'
  ]

  connect() {
    this.url = '/to_dos/quick_create_s'
    // console.log(this.urlValue)
    // console.log(this.url)
  }

  create() {
    event.preventDefault()
    let url = this.url
    let data = {
      to_do: {
        valid_from: this.valid_fromTarget.value,
        to_do_parent: this.to_do_parentTarget.value,
        title: this.titleTarget.value,
        points: this.pointsTarget.value,
        estimated_hours: this.estimated_hoursTarget.value,
        assignee_id: this.assignee_idTarget.value
      }
    }

    let todo = undefined
    let response = {}
    let context = this

    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }).then(function(response) {
      todo = response.json().then(function(todo) {
        // console.log(todo)
        let str = todo.template
        let parser = new DOMParser();
        let doc = parser.parseFromString(str, 'text/html');
        context.wrapperTarget.append(doc.body.children[0])
      })
    })
  }
}
