import { Controller } from "stimulus"
import { WhatsappChannel } from "channels/whatsapp_channel"
export default class extends Controller {
  static targets = ['messages']
  static values = { conversation: '' }

  initialize() {
  }

  scroll(event) {
    if(event.target.scrollTop <= 1){
      this._time = this.messagesTarget.dataset.time
      Rails.ajax({
        url: "/whatsapp/conversations/" + event.target.dataset.conversation + "/load_more",
        type: "get",
        data: "time=" + this._time,
        success: function(data) {},
        error: function(data) {}
      })
    }
  }

  connect() {
    let cid = null
    let messages = null
    if(this.hasMessagesTarget){
      this._time = this.messagesTarget.dataset.time
      cid = this.messagesTarget.dataset.cid
      messages = this.messagesTarget
    }
    WhatsappChannel(messages, cid)
    this.updateScroll()
  }
  updateScroll() {
    let element = document.getElementById("messages");
    if(element)
      element.scrollTop = element.scrollHeight;
  }
}
