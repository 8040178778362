import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['query', 'characteristic']

  connect() {
  }

  filter() {
    let query = this.queryTarget.value.toLowerCase()
    this.characteristicTargets.forEach(pc => {
      if(pc.dataset.tags.toLowerCase().includes(query)) {
        pc.classList.remove('dn')
      } else {
        pc.classList.add('dn')
      }
    })
  }
}
