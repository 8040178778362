import { Controller } from "stimulus"
import Quill from 'src/quill'
import Delta from 'quill-delta';
import { DirectUpload } from "@rails/activestorage"
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min";
import MagicUrl from 'quill-magic-url'
Quill.register('modules/magicUrl', MagicUrl)

export default class extends Controller {
  static targets = ['input', 'editor']

  prepareParams() {
    if(this.editor.getLength() > 1) {
      this.inputTarget.value = this.editor.root.innerHTML
    }
  }

  imageHandler(imageDataUrl, type, imageData) {
    const file = imageData.toFile()
    this.uploadImage(this, file)
  }

  uploadImage(context, file) {
    var upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        context.insertImage(
          context.editor,
          `https://yetto.do/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
        );
      }
    });
  }

  insertImage(textEditor, fileUrl) {
    const range = textEditor.getSelection();
    textEditor.insertEmbed(range.index, 'image', fileUrl);
  }

  connect() {
    this.inputTarget.form.addEventListener(
      'submit', this.prepareParams.bind(this)
    )
    this.editorTarget.innerHTML = this.inputTarget.value
    this.editor = new Quill(this.editorTarget, {
      theme: 'snow',
      modules: {
        magicUrl: true,
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          // [{ 'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          // [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block', 'image'],
          ['link'],
          ['clean']
        ],
        imageDropAndPaste: {
          handler: this.imageHandler.bind(this)
        },
        imageResize: {
          modules: ['Resize'],
          handleStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white'
          }
        }
      }
    });
    this.inputTarget.editor = this.editor
    this.editor.getModule('toolbar').addHandler(
      'image', this.importImage.bind(this)
    );

    // Autosave
    // Store accumulated changes
    var change = new Delta();
    this.editor.on('text-change', function(delta) {
      change = change.compose(delta);
    });

    // Save periodically
    setInterval(function() {
      if (change.length() > 0) {
        this.prepareParams()
        // console.log('Saving changes', change);
        /*
          Send partial changes
          $.post('/your-endpoint', {
            partial: JSON.stringify(change)
          });

          Send entire document
          $.post('/your-endpoint', {
            doc: JSON.stringify(quill.getContents())
          });
        */
        // debugger
        var current_state = this.editor.root.innerHTML
        // console.log(current_state)
        change = new Delta();
      }
    }.bind(this), 5*1000);

    // Check for unsaved data
    window.onbeforeunload = function() {
      // debugger
      if (change.length() > 0) {
        // window.lo
        // return 'There are unsaved changes. Are you sure you want to leave?';
      }
    }
  }

  importImage() {
    const textEditor = this.editor
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      // Ensure only images are uploaded
      if (/^image\//.test(file.type)) {
        this.uploadImage(this, file);
      } else {
        alert('Only images allowed');
      }
    };
  }
}
