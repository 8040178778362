function custom_alert(message, cb) {
  var callback = cb || function(){}
  var options = { title: "", text: message, icon: "info" };

  if(typeof(message) === "object") options = message;
  Swal.fire(options, callback);
}

function custom_confirm(message, success = null, fail = null) {
  var success_cb = success || function(){};
  var fail_cb = fail || function(){};
  var options = {
    title: "",
    text: message,
    icon: "warning",
    showCancelButton: true
  };

  if(typeof(message) === "object") options = message;

  return Swal.fire(options).then((result) => {
    if (result.value) return success_cb();
    fail_cb();
  })
}

function custom_prompt(message, value, callback) {
  var options = {
    title: "",
    text: message,
    icon: "question",
    showCancelButton: true,
    inputValue: value
  };

  if(typeof(message) === "object") options = message;

  Swal.fire(options, function(inputValue) {
    return callback ? callback(inputValue) : inputValue;
  })
}
window.original_alert = alert;
window.original_confirm = confirm;
window.original_prompt = prompt;
window.alert = custom_alert;
window.confirm = custom_confirm;
window.prompt = custom_prompt;

import Rails from "@rails/ujs";
// This file has to be required before rails-ujs
// To use it change `data-confirm` of your links to `data-confirm-swal`
(function() {
  // Hook the event before the other rails events so it works togeter
  // with `method: :delete`.
  // See https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/start.coffee#L69
  document.addEventListener('rails:attachBindings', element => {
    Rails.delegate(document, '[data-confirm]', 'confirm', function(e){
      if(e.target.dataset['confirmed'] === undefined){
        e.target.dataset['confirmed'] = false;
        custom_confirm(e.target.dataset['confirm'], function(){
          e.target.dataset['confirmed'] = true;
          delete(e.target.dataset['confirm']);
          if(e.target.nodeName === 'A') {
            Rails.fire(e.target, 'click');
          } else {
            $(e.target).trigger('click');
          }
        }, function(){
          delete(e.target.dataset['confirmed']);
        });
        return false;
      } else {
        let value = e.target.dataset['confirmed'] == 'true';
        delete(e.target.dataset['confirmed']);
        return value;
      }
    });
  })
}).call(this)

if (Rails.fire(document, "rails:attachBindings")) {
  Rails.start();
}
