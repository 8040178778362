import Quill from 'quill'
import { DirectUpload } from "@rails/activestorage"
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min";
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

// import Quill from 'quill/core';
//
// import Toolbar from 'quill/modules/toolbar';
// import Snow from 'quill/themes/snow';
//
// import Bold from 'quill/formats/bold';
// import Italic from 'quill/formats/italic';
// import Header from 'quill/formats/header';
//
//
// Quill.register({
//   'modules/toolbar': Toolbar,
//   'themes/snow': Snow,
//   'formats/bold': Bold,
//   'formats/italic': Italic,
//   'formats/header': Header
// });
//
//
// export default Quill
export default Quill
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
