import consumer from "./consumer"
import { notify } from "src/notifier"
export const WhatsappChannel = (element, cid) => {
  let args = {
    channel: "WhatsappChannel"
  }
  if(cid) {
    args.conversation_id = cid
  }
  consumer.subscriptions.create(args, {
    received(data) {
      this.processMsg(data)
    },
    
    processMsg(data) {
      if(element) this.displayMessage(data)
      if(data.message.fromMe !== 't') {
        notify(
          data.message.chatName, {
            body: data.message.body,
            silent: false,
            tag: 'renotify',
            renotify: true,
          }
        )
      }
    },
    displayMessage(data) {
      let message = data.message
      if(message.whatsapp_conversation_id != element.dataset.cid) return;

      let message_element = ''
      if(data.html_message == undefined || data.html_message == ''){
        message_element = this.renderMessage(message)
      } else {
        message_element = stringToHTML(data.html_message)
      }
      element.appendChild(message_element)
      element.scrollTop = element.scrollHeight;
    },

    renderMessage(message) {
      let message_element = document.createElement('li')
      message_element.classList = 'dark-gray items-center lh-copy cf'
      let class_list = message.fromMe == 'f' ? 'bg-black-05 fl b--light-gray' : 'dark-gray bg-light-green fr b--light-green'
      let date_time = new Date(time * 1000)
      date_time = date_time.getHours()+':'+date_time.getMinutes()
      message_element.innerHTML = "<div class='w-40 pa1 ma1 br1 ba " + class_list + "'><h6 class='black-30'>" + message.senderName + "</h6><small>" + message.body + "</small><span class='db tr mt2 black-40 f7'>"+date_time+"</span></div>"
      return message_element
    }
  })
}

export class WhatsappManager {
  static awaitNotifications() {
    consumer.subscriptions.create({ channel: "WhatsappChannel" }, {
      received(data) {
        console.log('Notification received')
      }
    })
  }
}
