import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['content']

  initialize() {
  }
  connect() {
  }
  display(event) {
    let uid = event.currentTarget.dataset.uid
    this.contentTarget.innerHTML = document.getElementById('contents_'+uid).innerHTML
  }
}
