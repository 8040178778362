import { Controller } from "stimulus"
export default class extends Controller {
  // COMMENTED TO AVOID INTERF. RGP 17-06-22
  // static targets = [
  //   'description', 'descriptionEditor', 'milestoneDescription',
  // ]
  //
  // connect() {
  //   if (this.hasDescriptionEditorTarget) {
  //     this.description_editor = this.initEditor(
  //       this.descriptionEditorTarget, this.descriptionTarget
  //     )
  //   }
  //   if (this.hasMilestoneDescriptionEditorTarget) {
  //     this.milestone_description_editor = this.initEditor(
  //       this.milestoneDescriptionEditorTarget, this.milestoneDescriptionTarget
  //     )
  //   }
  // }
  // EO: COMMENTED TO AVOID INTERF. RGP 17-06-22

  // function load_tickets(t) {
  //   really_load_tickets(t.value);
  // }
  //
  // function really_load_tickets(cid) {
  //   $('#choose-ticket').slideToggle();
  //   $.ajax('/tickets/open_tickets?client_id='+cid);
  // }
  //
  // if("#{@object.client.present?}" == 'true')
  //   really_load_tickets(#{@object.client&.id});
  // var element = document.querySelector('#ticket_client_id');
  // var choices = new Choices(element);
  // $('#new_ticket_toggle').on('click', function(e) {
  //   $('#create_ticket').slideToggle();
  //   $('#open_tickets').slideToggle();
  //   $('.ticket_option').prop("checked", false);
  // });
}
