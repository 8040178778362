import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import Quill from 'src/quill'
import MagicUrl from 'quill-magic-url'
Quill.register('modules/magicUrl', MagicUrl)

export default class extends Controller {
  static targets = ['description', 'editor']

  prepareParams() {
    this.descriptionTarget.value = this.description_editor.root.innerHTML
  }
  connect() {
    this.editorTarget.innerHTML = this.descriptionTarget.value
    this.description_editor = new Quill(this.editorTarget, {
      theme: 'snow',
      placeholder: "Write a description for the procedure...",
      modules: {
        magicUrl: true,
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-2'}, { 'indent': '+2' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block', 'image'],
          ['clean']
        ],

        imageResize: {
          displaySize: true,
          displayStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white'
          },
          modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        }
      }
    });

    var insertImage = function (textEditor, fileUrl) {
      const range = textEditor.getSelection();
      textEditor.insertEmbed(range.index, 'image', fileUrl);
    };

    var uploadImage = function (textEditor, file) {
      var fd = new FormData();
      fd.append('blob', file);

      var upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
      upload.create((error, blob) => {
        if (error) {
          console.log(error)
        } else {
          insertImage(
            textEditor,
            `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
          );
        }
      });
    };

    var importImage = function (textEditor) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();

      input.onchange = () => {
        const file = input.files[0];

        // Ensure only images are uploaded
        if (/^image\//.test(file.type)) {
          uploadImage(textEditor, file);
        } else {
          alert('Only images allowed');
        }
      };
    };

    this.description_editor.getModule('toolbar').addHandler('image', () => {
      importImage(this.description_editor);
    });
  }
}
