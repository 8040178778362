import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['sidebar', 'overlay', 'badge']

  connect() {
    if(
      window.notifications_counter === undefined ||
      window.notifications_counter === 0
    ){
      this.close()
    }
  }

  open() {
    this.sidebarTarget.classList.remove('dn')
    this.sidebarTarget.classList.remove('animate__slideOutRight')
    this.sidebarTarget.classList.add('animate__slideInRight')
    this.overlayTarget.classList.remove('dn')
  }

  close() {
    this.overlayTarget.classList.add('dn')
    this.sidebarTarget.classList.add('animate__slideOutRight')
    this.sidebarTarget.classList.remove('animate__slideInRight')
  }
  new(event) {
    let new_val = parseInt(this.badgeTarget.dataset.Unseen) + 1
    this.badgeTarget.innerHTML = new_val
    this.badgeTarget.dataset.Unseen = new_val
  }
}
