import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cc_column', 'pc_list', 'main_column']

  toggle() {
    this.cc_columnTarget.classList.toggle('w-30')
    this.cc_columnTarget.classList.toggle('w2')
    this.main_columnTarget.classList.toggle('w-70')
    this.main_columnTarget.classList.toggle('w-100')
    this.pc_listTarget.classList.toggle('dn')
  }
  connect() {
    console.log('aa')
  }
}

