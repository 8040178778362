import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['quoted', 'comment', 'quotedCommentDisplay', 'quotedCommentBody']

  connect() {
  }

  reply(e) {
    var quoted_comment_id = e.currentTarget.dataset.commentId;
    this.quotedTarget.value = quoted_comment_id
    this.quotedCommentDisplayTarget.classList.remove('dn')
    var id = "comm-" + quoted_comment_id
    var wrapper = document.getElementById(id)
    var body_holder = wrapper.getElementsByClassName('quoteable_body')[0]
    var body = body_holder.innerHTML
    this.quotedCommentBodyTarget.innerHTML = body
    this.commentTarget.scrollIntoView()
  }
  abortReply() {
    this.quotedCommentDisplayTarget.classList.add('dn')
    this.quotedTarget.value = ''
    this.quotedCommentBodyTarget.innerHTML = ''
  }

  scrollTo(e) {
    var id = e.currentTarget.dataset.quotedId
    document.getElementById(id).scrollIntoView()
  }
}
