import sound from 'sounds/notification.mp3'

export class Notifier {
  constructor(){
    this.verifySupport()
    setTimeout(this.requestPermission, 2000)
  }

  notify(message, opts) {
    if(!this.supported) return

    let default_options = { }
    let options = {
      ...default_options,
      ...opts
    };
    let audio = new Audio(sound)
    // audio.play()
    // Patch for iOS Safari
    if(typeof Notification === 'undefined') return;

    new Notification(message, options)
  }

  requestPermission() {
    // Patch for iOS Safari
    if(typeof Notification === 'undefined') return

    if (Notification.permission === 'granted') return true

    Swal.fire({
      text: 'Please enable notifications',
      icon: "info",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Enable',
      cancelButtonText: 'Ignore',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire('Saved!', '', 'success')
        Notification.requestPermission().then(function (p) {
          if (p === 'denied') {
            // confirm('Please enable notifications')
          } else {
          }
        }).catch(function (err) {
          console.error(err);
        });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  verifySupport() {
    if (!window.Notification) {
      console.log('Browser does not support notifications.');
      this.supported = false
    }
    this.supported = true
  }
}
const notifier = new Notifier()
export const notify = (message, opts = {}) => notifier.notify(message, opts)
