import { Controller } from "stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default class extends Controller {
  static targets = ['title', 'subobjective', 'dependency']

  // clearDate() {
  //   this.completedAtTarget.value = ''
  // }
  toggleReleases() {
    const releases_divs = document.querySelectorAll(".releases");
    for (let i = 0; i < releases_divs.length; i++) {
      releases_divs[i].classList.toggle('dn')
    }
  }
  addSubobjective(e) {
    e.preventDefault()
    const el = document.getElementById('new_subobjective_' + e.currentTarget.dataset.oid)
    el.classList.remove('dn')
  }

  connect() {
    const today = document.getElementById('today')
    if(today)
      today.scrollIntoView(
        { behavior: "instant", block: "center", inline: "center" }
      )
    tippy(this.titleTargets, {
      content(template) {
        return JSON.parse(template.dataset.template)
      },
      allowHTML: true,
      placement: 'left',
      theme: 'light',
      interactive: true,
      appendTo: () => this.context.element,
    });
    tippy(this.dependencyTargets, {
      content(template) {
        return template.firstChild.innerHTML
      },
      allowHTML: true,
      placement: 'top',
      theme: 'light',
      interactive: true,
      maxWidth: 'none',
    });
  }
}
