import { Controller } from "stimulus"
import { Sortable, Plugins } from '@shopify/draggable'
export default class extends Controller {
  static targets = ['container', 'ticket']

  connect() {
    const sortable = new Sortable(this.containerTargets, {
      draggable: `.ticket`,
      mirror: {
        constrainDimensions: true,
      },
      plugins: [Plugins.ResizeMirror],
    });

    sortable.on('sortable:stop', (evt) => {
      if(evt.newContainer != evt.oldContainer){
        Rails.ajax({
          url: "/clients/update_ticket",
          type: "post",
          data: "tid="+evt.data.dragEvent.originalSource.dataset.tid+"&kcid="+evt.newContainer.dataset.kcid,
          success: function(data) {},
          error: function(data) {}
        })
      }
    })
  }

}
