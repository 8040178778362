import { Controller } from "stimulus"
import Quill from 'src/quill'
import MagicUrl from 'quill-magic-url'
Quill.register('modules/magicUrl', MagicUrl)

export default class extends Controller {
  static targets = [
    'description', 'descriptionEditor', 'milestoneDescription',
    'milestoneDescriptionEditor'
  ]

  prepareParams() {
    if (this.hasDescriptionEditorTarget) {
      if(this.description_editor.getText().trim() == '') {
        this.descriptionTarget.value = ''
      } else {
        this.descriptionTarget.value = this.description_editor.root.innerHTML
      }
    }
    if (this.hasMilestoneDescriptionEditorTarget) {
      if(this.milestone_description_editor.getText().trim() == '') {
        this.milestoneDescriptionTarget.value = ''
      } else {
        this.milestoneDescriptionTarget.value = this.milestone_description_editor.root.innerHTML
      }
    }
  }
  connect() {
    if (this.hasDescriptionEditorTarget) {
      this.description_editor = this.initEditor(
        this.descriptionEditorTarget, this.descriptionTarget
      )
    }
    if (this.hasMilestoneDescriptionEditorTarget) {
      this.milestone_description_editor = this.initEditor(
        this.milestoneDescriptionEditorTarget, this.milestoneDescriptionTarget
      )
    }
  }

  initEditor(editorTarget, field) {
    editorTarget.innerHTML = field.value
    let quill = new Quill(editorTarget, {
      theme: 'snow',
      modules: {
        magicUrl: true,
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block'],
          ['link'],
          ['clean']
        ]
      }
    });
    quill.clipboard.addMatcher('IMG', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    })
    quill.clipboard.addMatcher('PICTURE', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    })
    return quill;
  }
}
